import { useUser } from "../use-user";

export const useAccountType = () => {
  const user = useUser();

  const accountType = user?.account_type;
  const isFreelancerApproved = !!user?.freelancer_approved;
  const isFreelancer = accountType === ENUMS.AccountType.FREELANCER;
  const isEmployer = accountType === ENUMS.AccountType.EMPLOYER;
  const isUserVerified = !!user?.is_verified;
  const isNodeStaff = !!user?.has_node_staff_permissions;

  return {
    isFreelancerApproved,
    isFreelancer,
    isEmployer,
    accountType,
    isUserVerified,
    isNodeStaff,
  };
};
