import * as Sentry from "@sentry/react";
import { z } from "zod";

import { RATE_OPTIONS } from "@js/apps/jobs/apps/create-job/constants";
import type { PreparedJobDraftData } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

const manageJobErrorsSchema = z
  .object({
    job_subscribers: z
      .union([
        z
          .object({
            team_member_id: z.coerce.string().optional(),
            _error: z.coerce.string().optional(),
          })
          .strict()
          .array()
          .transform((value) => {
            const teamMemberIdError = value?.find(
              (errorObject) => !!errorObject.team_member_id,
            )?.team_member_id;

            const generalError = value?.find(
              (errorObject) => !!errorObject._error,
            )?._error;

            return teamMemberIdError || generalError;
          }),
        z
          .object({ _error: z.coerce.string() })
          .strict()
          .transform((value) => {
            return value?._error;
          }),
        z.coerce.string(),
      ])
      .optional(),
    job_subscriber_invitations: z
      .union([
        z
          .object({
            email: z.coerce.string().optional(),
            _error: z.coerce.string().optional(),
          })
          .strict()
          .array()
          .transform((value) => {
            const email = value?.find(
              (errorObject) => !!errorObject.email,
            )?.email;

            const generalError = value?.find(
              (errorObject) => !!errorObject._error,
            )?._error;

            return email || generalError;
          }),
        z
          .object({ _error: z.coerce.string() })
          .strict()
          .transform((value) => {
            return value?._error;
          }),
        z.coerce.string(),
      ])
      .optional(),
    email: z.string().optional(),
    timezone_overlap: z.coerce.string().optional(),
    payment_type: z.string().array().optional(),
    budget_minimum_usd: z.string().array().optional(),
    budget_maximum_usd: z.string().array().optional(),
    new_application_questions: z
      .array(
        z
          .object({ question: z.coerce.string().optional() })
          .strict("Not covered error key"),
      )
      .optional(),
    _error: z.union([z.array(z.string()), z.string()]).optional(),
  })
  .catchall(z.coerce.string().optional());

export type ManageJobErrors = z.infer<typeof manageJobErrorsSchema>;
type ManageJobErrorsMapped = ManageJobErrors & {
  fixed_rate?: string[];
  min_rate?: string[];
  max_rate?: string[];
  hourly_rate?: string[];
  min_annual_rate?: string[];
  max_annual_rate?: string[];
  job_subscriber_invitations?: string;
  payment_type?: string[];
};

export type MapManageJobErrorsJobValues = Partial<
  Pick<
    PreparedJobDraftData,
    | "payment_type"
    | "budget_minimum_usd"
    | "budget_maximum_usd"
    | "timezone_overlap"
    | "job_fill_support_type"
  >
>;

export const mapRateErrorsToFrontend = (
  values: MapManageJobErrorsJobValues,
  errors: ManageJobErrors,
) => {
  const newErrors: ManageJobErrorsMapped = {
    ...errors,
  };

  const paymentType = values.payment_type;

  if (!paymentType) {
    newErrors.payment_type = errors.payment_type;
    return newErrors;
  }

  switch (paymentType) {
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      if (errors.budget_minimum_usd || errors.budget_maximum_usd) {
        newErrors.fixed_rate =
          errors.budget_minimum_usd || errors.budget_maximum_usd;
      }

      break;
    }
    case ENUMS.JobPaymentType.HOURLY: {
      const isRateTheSame =
        values.budget_minimum_usd === values.budget_maximum_usd;
      const isRangeSelected = isRateTheSame
        ? !RATE_OPTIONS.find(
            (option) => option.value === values.budget_minimum_usd,
          )
        : true;

      if (isRangeSelected) {
        newErrors.min_rate = errors.budget_minimum_usd;
        newErrors.max_rate = errors.budget_maximum_usd;
      } else {
        newErrors.hourly_rate =
          errors.budget_minimum_usd || errors.budget_maximum_usd;
      }
      break;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      newErrors.min_annual_rate = errors.budget_minimum_usd;
      newErrors.max_annual_rate = errors.budget_maximum_usd;
      break;
    }
    default: {
      assertUnreachable(paymentType);
      break;
    }
  }

  delete newErrors.budget_minimum_usd;
  delete newErrors.budget_maximum_usd;

  return newErrors;
};

export const mapManageJobErrors = (
  values: MapManageJobErrorsJobValues,
  errors: unknown,
) => {
  const parseErrorsResult = manageJobErrorsSchema.safeParse(errors);

  if (
    !parseErrorsResult.success ||
    Object.values(parseErrorsResult.data).includes("[object Object]")
  ) {
    Sentry.captureException("mapManageJobErrors", {
      extra: { errors },
    });
  }

  if (!parseErrorsResult.success) {
    return errors as Record<string, string | undefined>;
  }

  const mappedErrors = mapRateErrorsToFrontend(values, parseErrorsResult.data);

  if (parseErrorsResult.data.email) {
    mappedErrors.job_subscriber_invitations = parseErrorsResult.data.email;
    delete mappedErrors.email;
  }

  return mappedErrors;
};
