import { useCallback } from "react";
import { SubmissionError } from "redux-form";

import { API } from "@js/api";
import {
  useCreateJobMutation,
  useDeleteJobDraftMutation,
} from "@js/apps/jobs/api";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { CreateJobData } from "@js/types/jobs";
import { typeGuard } from "@js/utils";

import { mapManageJobErrors } from "../../utils/error-mapping";

export const useCreateJobAndRemoveJobDraft = () => {
  const [deleteJobDraft] = useDeleteJobDraftMutation();
  const [createJob] = useCreateJobMutation();
  const dispatch = useAppDispatch();

  const createJobAndDeleteJobDraft = useCallback(
    async (data: CreateJobData) => {
      const { job_draft_id, dry_run } = data;
      try {
        const createdJob = await createJob(data).unwrap();
        if (dry_run || !job_draft_id) {
          return createdJob;
        }

        await deleteJobDraft({ id: job_draft_id })
          .unwrap()
          .catch(() => {
            Snackbar.error("Failed to delete draft.");
          });

        return createdJob;
      } catch (error) {
        if (data.public_after_approval) {
          dispatch(API.util.invalidateTags(["ManagedEmployers"]));
        }

        if (!typeGuard<unknown, { data: unknown }>(error, "data")) {
          throw new SubmissionError({ _error: "Failed to create job" });
        }

        const newErrors = mapManageJobErrors(data, error.data);
        throw new SubmissionError(newErrors);
      }
    },
    [createJob, deleteJobDraft, dispatch],
  );

  return { createJobAndDeleteJobDraft };
};
